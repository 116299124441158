import React                from "react";
import PropTypes            from "prop-types";
import useDialog            from "Dashboard/Hooks/Dialog";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Provider Log View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProviderLogView(props) {
    const { open, elemID, onClose } = props;

    // Load the Data
    const { loading, elem } = useDialog("providerLog", open, elemID);


    // Do the Render
    return <ViewDialog
        open={open}
        icon="provider-log"
        title="PROVIDERS_LOG_VIEW_TITLE"
        onClose={onClose}
        isLoading={loading}
        withSpacing
    >
        <Columns>
            <ViewField
                label="GENERAL_TIME"
                value={elem.createdDate}
            />
            <ViewField
                label="GENERAL_TYPE"
                value={elem.submitType}
                viewClass={elem.errorClass}
            />

            <ViewField
                label="PROVIDERS_LOG_PROVIDER"
                value={elem.provider}
            />
            <ViewField
                label="PROVIDERS_LOG_ACTION"
                value={elem.action}
            />
        </Columns>

        <ViewField
            label="GENERAL_URL"
            value={elem.url}
        />
        <ViewField
            label="ORDERS_SINGULAR"
            value={elem.orderText}
        />
        <ViewField
            label="PROVIDERS_LOG_REQUEST"
            value={Utils.jsonToHtml(elem.request)}
            copyValue={JSON.stringify(elem.request, null, 4)}
            hasCopy
        />
        <ViewField
            label="PROVIDERS_LOG_RESPONSE"
            value={Utils.jsonToHtml(elem.response)}
        />
    </ViewDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProviderLogView.propTypes = {
    open    : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
    elemID  : PropTypes.number,
};

export default ProviderLogView;
