import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Dashboard
import IconLink             from "Dashboard/Components/Link/IconLink";



// Styles
const Container = Styled.h3`
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    font-family: var(--title-font);
    font-weight: var(--title-font-weight);
    font-size: 18px;
`;



/**
 * The Daily Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DailyHeader(props) {
    const { isHidden } = props;

    const { currentDate } = Store.useState("dashboardDaily");
    const { setCurrentDate } = Store.useAction("dashboardDaily");


    // Handles the Prev day
    const handlePrev = () => {
        setCurrentDate(currentDate.moveDay(-1));
    };

    // Handles the Next day
    const handleNext = () => {
        setCurrentDate(currentDate.moveDay(1));
    };


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <Container>
        <IconLink
            icon="prev"
            onClick={handlePrev}
            isSmall
        />
        {currentDate.toString("dayMonth")}
        <IconLink
            isHidden={currentDate.isToday}
            icon="next"
            onClick={handleNext}
            isSmall
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DailyHeader.propTypes = {
    isHidden : PropTypes.bool.isRequired,
};

export default DailyHeader;
