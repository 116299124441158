import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import ViewColumns          from "Components/Utils/View/ViewColumns";
import ViewItem             from "Components/Utils/View/ViewItem";
import Html                 from "Dashboard/Components/Common/Html";



// Styles
const Container = Styled(ViewColumns)`
    flex-grow: 2;
`;



/**
 * The Product General
 * @returns {React.ReactElement}
 */
function ProductGeneral() {
    const { elem, spaceNames } = Store.useState("product");


    // Do the Render
    return <Container message="PRODUCTS_GENERAL" amount="4">
        <ViewItem
            message="GENERAL_CODE_BENVIDA"
            value={elem.benvidaID}
        />
        <ViewItem
            message="GENERAL_CODE_ALFABETA"
            value={elem.alfabetaID}
        />
        <ViewItem
            message="GENERAL_BAR_CODES"
            value={elem.barCodesText}
        />

        <ViewItem
            message="MACRO_CATEGORIES_SINGULAR"
            value={elem.macroCategoryName}
        />
        <ViewItem
            message="CATEGORIES_SINGULAR"
            value={elem.categoryName}
        />
        <ViewItem
            message="SUB_CATEGORIES_SINGULAR"
            value={elem.subCategoryName}
        />
        <ViewItem
            message="SPACES_NAME"
            value={spaceNames.join(", ")}
        />

        <ViewItem message="GENERAL_PRICE">
            <Html>{elem.priceText}</Html>
        </ViewItem>
        <ViewItem
            message="GENERAL_PRICE_VALIDITY"
            value={elem.validityDate}
        />
        <ViewItem message="MEDICINES_PAMI_PRICE">
            <Html>{elem.pamiPriceText}</Html>
        </ViewItem>

        <ViewItem
            message="LABORATORIES_SINGULAR"
            value={elem.laboratoryName}
            viewClass={elem.laboratoryIDClass}
        />
        <ViewItem
            message="SALE_TYPES_SINGULAR"
            value={elem.saleTypeName}
            viewClass={elem.saleTypeIDClass}
        />
        <ViewItem
            message="SHAPE_TYPES_SINGULAR"
            value={elem.shapeTypeName}
            viewClass={elem.shapeTypeIDClass}
        />
        <ViewItem
            message="MONO_DRUGS_SINGULAR"
            value={elem.monoDrugName}
            viewClass={elem.monoDrugIDClass}
        />
        <ViewItem
            message="PHARMA_ACTIONS_SINGULAR"
            value={elem.pharmaActionName}
            viewClass={elem.pharmaActionIDClass}
        />

        <ViewItem
            message="GENERAL_POTENCY"
            value={elem.potencyText}
            viewClass={elem.potencyClass}
        />
        <ViewItem
            message="PRODUCTS_QUANTITY_PER_CONTAINER"
            value={elem.units}
        />
        <ViewItem
            message="GENERAL_PROSPECTO"
            value={elem.prospecto}
            hideEmpty
        />

        <ViewItem
            message="GENERAL_FOR_CELIAC"
            value={elem.forCeliacText}
            viewClass={elem.forCeliacClass}
        />
        <ViewItem
            message="GENERAL_REQ_REFRIGERATION"
            value={elem.reqRefrigerationText}
            viewClass={elem.reqRefrigerationClass}
        />
        <ViewItem
            message="GENERAL_REQ_RECIPE"
            value={elem.reqRecipeText}
        />
        <ViewItem
            message="GENERAL_IS_ACTIVE"
            value={elem.isActiveText}
            viewClass={elem.isActiveClass}
        />

        <ViewItem
            message="GENERAL_CREATED_AT"
            value={elem.createdTimeDate}
        />
        <ViewItem
            message="GENERAL_MODIFIED_AT"
            value={elem.updatedTimeDate}
        />
        <ViewItem
            isHidden={!elem.origin}
            message="GENERAL_ORIGIN"
            value={elem.originText}
        />
    </Container>;
}

export default ProductGeneral;
