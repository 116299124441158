import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";

// Components
import ProcessFile          from "Components/App/Dashboard/Process/ProcessFile";

// Dashboard
import IconLink             from "Dashboard/Components/Link/IconLink";
import PDFViewer            from "Dashboard/Components/Media/PDFViewer";
import Image                from "Dashboard/Components/Common/Image";



// Styles
const Container = Styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    @media screen and (max-width: 900px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;

const Title = Styled.h3`
    margin: 0;
    padding: 0 0 12px 4px;
    color: var(--alternative-color);
    font-family: var(--title-font);
    font-weight: var(--title-font-weight);
    font-size: 18px;
`;

const Content = Styled.div`
    position: relative;
    flex-direction: column;
    border: 1px solid var(--border-color-light);
    padding: var(--main-gap);
    border-radius: var(--border-radius);
    overflow: hidden;
`;

const Inside = Styled.div`
    height: 200px;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    canvas {
        width: 100%;
    }
`;

const Link = Styled(IconLink)`
    position: absolute;
    top: 4px;
    right: 4px;
`;



/**
 * The Order Recipes
 * @returns {React.ReactElement}
 */
function OrderRecipes() {
    const { elem } = Store.useState("order");


    // The Current State
    const [ showRecipe, setShowRecipe ] = React.useState({ url : "", isImage : false, isPDF : false });


    // Do the Render
    if (!elem.recipeFiles) {
        return <React.Fragment />;
    }
    return <>
        <Container>
            {elem.recipeFiles.map((item, index) => <Content key={index}>
                <Title>{NLS.format("ORDERS_RECIPE_OF", index + 1, elem.recipeFiles.length)}</Title>
                <Inside onClick={() => setShowRecipe(item)}>
                    {item.isPDF && <PDFViewer
                        source={item.url}
                        maxWidth={280}
                    />}
                    {item.isImage && <Image
                        source={item.url}
                    />}
                </Inside>
                <Link
                    icon="zoom"
                    onClick={() => setShowRecipe(item)}
                />
            </Content>)}
        </Container>

        <ProcessFile
            open={Boolean(showRecipe.url)}
            isPDF={showRecipe.isPDF}
            isImage={showRecipe.isImage}
            fileUrl={showRecipe.url}
            onClose={() => setShowRecipe({ url : "", isImage : false, isPDF : false })}
        />
    </>;
}

export default OrderRecipes;
