import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import ViewSection          from "Components/Utils/View/ViewSection";
import ChatContent          from "Components/Utils/Chat/ChatContent";



// Styles
const Container = Styled(ViewSection)`
    grid-area: chat;
    max-height: 640px;
    overflow: auto;
    padding: 0 0 16px 0 !important;

    h3 {
        padding: 16px 16px 0 16px;
    }

    @media screen and (max-width: 600px) {
        max-height: none;
    }
`;



/**
 * The Order Chat
 * @returns {React.ReactElement}
 */
function OrderChat() {
    const { states, chatItems } = Store.useState("order");


    // Do the Render
    if (!states.length) {
        return <React.Fragment />;
    }
    return <Container message="ORDERS_CHAT_TRANSCRIPTION">
        <ChatContent
            items={chatItems}
        />
    </Container>;
}

export default OrderChat;
