import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";

// Components
import OperativeMenu        from "Components/Core/User/Operative/OperativeMenu";
import OperativeRouter      from "Components/Core/User/Operative/OperativeRouter";
import SuperOperativeMenu   from "Components/Core/User/SuperOperative/SuperOperativeMenu";
import SuperOperativeRouter from "Components/Core/User/SuperOperative/SuperOperativeRouter";
import SupervisorRouter     from "Components/Core/User/Supervisor/SupervisorRouter";
import SupervisorMenu       from "Components/Core/User/Supervisor/SupervisorMenu";
import CatalogMenu          from "Components/Core/Admin/Catalog/CatalogMenu";
import CatalogRouter        from "Components/Core/Admin/Catalog/CatalogRouter";
import FinanceMenu          from "Components/Core/Admin/Finance/FinanceMenu";
import FinanceRouter        from "Components/Core/Admin/Finance/FinanceRouter";
import AdminRouter          from "Components/Core/Admin/Admin/AdminRouter";
import AdminMenu            from "Components/Core/Admin/Admin/AdminMenu";
import SuperAdminRouter     from "Components/Core/Admin/SuperAdmin/SuperAdminRouter";
import SuperAdminMenu       from "Components/Core/Admin/SuperAdmin/SuperAdminMenu";

// Dashboard
import Container            from "Dashboard/Components/Core/Container";
import TopBar               from "Dashboard/Components/Core/TopBar";
import SideBar              from "Dashboard/Components/Core/SideBar";
import Inside               from "Dashboard/Components/Core/Inside";
import MenuItem             from "Dashboard/Components/Menu/MenuItem";
import Image                from "Dashboard/Components/Common/Image";

// Images
import Logo                 from "Styles/Images/Logo.png";



// Styles
const Title = Styled.h2`
    margin: 0 24px 0 0;
    font-family: var(--title-font);
    font-weight: var(--title-font-weight);
    font-size: 18px;
`;



/**
 * The App
 * @returns {React.ReactElement}
 */
function App() {
    const navigate = Navigate.useGoto();

    const { credential, edition } = Store.useState("auth");
    const { logout } = Store.useAction("auth");
    const { hideTooltip } = Store.useAction("core");


    // Handles the User Logout
    const handleLogout = async () => {
        hideTooltip();
        const result = await logout();
        if (result.isAnyUser) {
            if (result.subsidiaryID) {
                navigate("BUSINESS", "SUBSIDIARIES", result.subsidiaryID, "USERS");
            } else {
                navigate("BUSINESS", "USERS");
            }
        } else if (result.isAnyAdmin) {
            navigate("SETTINGS", "ADMIN");
        }
    };



    // Variables
    const showSubsidiary = Boolean(credential.subsidiaryFullName);
    const showPharmacy   = Boolean(credential.pharmacyName && !credential.subsidiaryFullName);


    // Do the Render
    return <Container withTopBar>
        <TopBar
            variant="light"
            logo={Logo}
            avatarUrl="PROFILE"
            avatarEmail={credential.email}
            avatarAvatar={credential.avatar}
            avatarEdition={edition}
            withDev
            menuItems={[
                <MenuItem
                    key="user"
                    icon="user"
                    message="ACCOUNT_MINE"
                    url="PROFILE"
                />,
                <MenuItem
                    key="logout"
                    icon="logout"
                    message="GENERAL_LOGOUT"
                    onClick={handleLogout}
                />,
            ]}
        >
            {showSubsidiary && <>
                <Image
                    source={credential.subsidiaryImage}
                    message={credential.subsidiaryFullName}
                    size="32"
                />
                <Title>{credential.subsidiaryFullName}</Title>
            </>}
            {showPharmacy && <>
                <Image
                    source={credential.pharmacyImage}
                    message={credential.pharmacyName}
                    size="32"
                />
                <Title>{credential.pharmacyName}</Title>
            </>}
        </TopBar>

        <SideBar
            variant="accent"
            topSpace="40"
            expandMobile
        >
            <OperativeMenu />
            <SuperOperativeMenu />
            <SupervisorMenu />

            <CatalogMenu />
            <FinanceMenu />
            <AdminMenu />
            <SuperAdminMenu />
        </SideBar>

        <Inside>
            <OperativeRouter />
            <SuperOperativeRouter />
            <SupervisorRouter />

            <CatalogRouter />
            <FinanceRouter />
            <AdminRouter />
            <SuperAdminRouter />
        </Inside>
    </Container>;
}

export default App;
