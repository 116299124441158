import React                from "react";
import PropTypes            from "prop-types";

// Components
import ViewColumns          from "Components/Utils/View/ViewColumns";
import ViewItem             from "Components/Utils/View/ViewItem";

// Dashboard
import Html                 from "Dashboard/Components/Common/Html";



/**
 * The Medicine General
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MedicineGeneral(props) {
    const { elem } = props;


    // Do the Render
    if (!elem.id) {
        return <React.Fragment />;
    }
    return <ViewColumns message="MEDICINES_GENERAL" amount="4">
        <ViewItem
            message="GENERAL_CODE_ALFABETA"
            value={elem.alfabetaID}
        />
        <ViewItem
            message="GENERAL_BAR_CODES"
            value={elem.barCodesText}
        />
        <ViewItem
            message="MEDICINES_GTIN_CODES"
            value={elem.gtinCodesText}
        />
        <ViewItem
            message="MEDICINES_TROQUEL"
            value={elem.troquel}
        />
        <ViewItem
            message="MEDICINES_SNOMED"
            value={elem.snomed}
            hideEmpty
        />

        <ViewItem message="GENERAL_PRICE">
            <Html>{elem.priceText}</Html>
        </ViewItem>
        <ViewItem
            message="GENERAL_PRICE_VALIDITY"
            value={elem.validityDate}
        />

        <ViewItem
            message="LABORATORIES_SINGULAR"
            value={elem.laboratoryName}
            viewClass={elem.laboratoryIDClass}
        />
        <ViewItem
            message="MONO_DRUGS_SINGULAR"
            value={elem.monoDrugName}
            viewClass={elem.monoDrugIDClass}
        />

        <ViewItem
            message="SALE_TYPES_SINGULAR"
            value={elem.saleTypeName}
            viewClass={elem.saleTypeIDClass}
        />
        <ViewItem
            message="HEALTH_TYPES_SINGULAR"
            value={elem.healthTypeName}
        />
        <ViewItem
            message="SIZE_TYPES_SINGULAR"
            value={elem.sizeTypeName}
        />
        <ViewItem
            message="SHAPE_TYPES_SINGULAR"
            value={elem.shapeTypeName}
            viewClass={elem.shapeTypeIDClass}
        />

        <ViewItem
            message="MEDICINES_PRESENTATION"
            value={elem.presentation}
        />
        <ViewItem
            message="GENERAL_POTENCY"
            value={elem.potencyText}
            viewClass={elem.potencyClass}
        />
        <ViewItem
            message="MEDICINES_UNITS"
            value={elem.unitsText}
        />
        <ViewItem
            message="ADMIN_ROUTES_SINGULAR"
            value={elem.administrationRouteName}
        />
        <ViewItem
            message="PHARMA_ACTIONS_SINGULAR"
            value={elem.pharmaActionName}
            viewClass={elem.pharmaActionIDClass}
        />

        <ViewItem
            message="GENERAL_PROSPECTO"
            value={elem.prospecto}
            hideEmpty
        />
        <ViewItem
            message="MEDICINES_PAMI_PERCENT"
            value={elem.pamiPercentText}
            hideEmpty
        />
        <ViewItem message="MEDICINES_PAMI_PRICE">
            <Html>{elem.pamiPriceText}</Html>
        </ViewItem>
        <ViewItem
            message="MEDICINES_IOMA_PRICE"
            value={elem.iomaPrice}
            hideEmpty
        />
        <ViewItem
            message="MEDICINES_IOMA_NORMATIZADO"
            value={elem.iomaNormatizadoText}
            hideEmpty
        />
        <ViewItem
            message="MEDICINES_FOR_SIFAR"
            value={elem.forSIFARText}
            hideEmpty
        />

        <ViewItem
            message="GENERAL_FOR_CELIAC"
            value={elem.forCeliacText}
            viewClass={elem.forCeliacClass}
        />
        <ViewItem
            message="GENERAL_REQ_REFRIGERATION"
            value={elem.reqRefrigerationText}
            viewClass={elem.reqRefrigerationClass}
        />
        <ViewItem
            message="MEDICINES_HAS_TAX"
            value={elem.hasTaxText}
        />
        <ViewItem
            message="MEDICINES_HAS_GRAVAMEN"
            value={elem.hasGravamenText}
        />
        <ViewItem
            message="MEDICINES_IS_IMPORTED"
            value={elem.isImportedText}
        />
        <ViewItem
            message="GENERAL_IS_ACTIVE"
            value={elem.isActiveText}
            viewClass={elem.isActiveClass}
        />
        <ViewItem
            message="MEDICINES_TO_DELETE"
            value={elem.toDeleteText}
        />
    </ViewColumns>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MedicineGeneral.propTypes = {
    elem : PropTypes.object,
};

export default MedicineGeneral;
