import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";

// Components
import OrderProducts        from "Components/App/Order/Order/Dialogs/OrderProducts";



// Styles
const Products = Styled.section`
    grid-area: products;
    position: relative;
`;

const Title = Styled.h3`
    margin: 0 0 8px 8px;
    color: var(--alternative-color);
    font-family: var(--title-font);
    font-weight: var(--title-font-weight);
    font-size: 18px;
`;



/**
 * The Process View Products
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProcessProducts(props) {
    const { unitPrices, prices, subTotals, data, errors, onChange } = props;

    const { elem, tooltips, saleTypes, canWaitOrder } = Store.useState("dashboardProcess");


    // Do the Render
    return <>
        <Products>
            <Title>{NLS.get("PRODUCTS_NAME")}</Title>
            <OrderProducts
                tooltips={tooltips}
                saleTypes={saleTypes}
                unitPrices={unitPrices}
                prices={prices}
                subTotals={subTotals}
                data={data}
                errors={errors}
                onChange={onChange}
                canEditProducts={elem.canEditOrder}
                canWaitProducts={canWaitOrder}
            />
        </Products>
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProcessProducts.propTypes = {
    unitPrices : PropTypes.object.isRequired,
    prices     : PropTypes.object.isRequired,
    subTotals  : PropTypes.object.isRequired,
    data       : PropTypes.object.isRequired,
    errors     : PropTypes.object.isRequired,
    onChange   : PropTypes.func.isRequired,
};

export default ProcessProducts;
