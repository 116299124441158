import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";



// Styles
const Container = Styled.div`
    thead {
        border-radius: 0;
    }

    @media (max-width: 700px) {
        tbody {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    div h3 {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
`;

const Title = Styled.h3`
    margin: 0;
    padding: 12px 16px;
    color: var(--alternative-color);
    font-family: var(--title-font);
    font-weight: var(--title-font-weight);
    font-size: 18px;
    border: 1px solid var(--border-color-light);
    border-bottom: none;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
`;



/**
 * The View Section
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ViewSection(props) {
    const { className, message, children } = props;


    // Do the Render
    return <Container className={className}>
        <Title>{NLS.get(message)}</Title>
        {children}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ViewSection.propTypes = {
    className : PropTypes.string,
    message   : PropTypes.string.isRequired,
    children  : PropTypes.any,
};

export default ViewSection;
