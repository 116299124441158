import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";



// Styles
const Container = Styled.h3`
    margin: 0;
    color: var(--alternative-color);
    font-family: var(--title-font);
    font-weight: var(--title-font-weight);
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;



/**
 * The Dashboard Title
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardTitle(props) {
    const { message } = props;


    // Do the Render
    return <Container>
        {NLS.get(message)}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardTitle.propTypes = {
    message : PropTypes.string.isRequired,
};

export default DashboardTitle;
